window.jQuery = require('jquery');
window.jQueryFlexSlider = require('flexslider');
console.log('hi dom');

import 'lazysizes';

import flexslider from 'flexslider';

document.addEventListener("DOMContentLoaded", () => {

    const navOverlay = document.getElementById('navOverlay');
    const globalAlert = document.getElementById('globalAlert');
    const dismissAlert = document.getElementById('dismissAlert');
    let globalAlertHeight = null;

    const create_cookie = (name, value, days2expire, path) => {
        let date = new Date();
        date.setTime(date.getTime() + (days2expire * 24 * 60 * 60 * 1000));
        let expires = date.toUTCString();
        document.cookie = name + '=' + value + ';' +
            'expires=' + expires + ';' +
            'path=' + path + ';';
    }

    const retrieve_cookie = (name) => {
        let cookie_value = "",
            current_cookie = "",
            name_expr = name + "=",
            all_cookies = document.cookie.split(';'),
            n = all_cookies.length;

        for(let i = 0; i < n; i++) {
            current_cookie = all_cookies[i].trim();
            if (current_cookie.indexOf(name_expr) == 0) {
                cookie_value = current_cookie.substring(name_expr.length, current_cookie.length);
                break;
            }
        }
        return cookie_value;
    }

    const globalAlertCookie = retrieve_cookie('dismissGlobalAlert');

    if (globalAlert && globalAlertCookie !== 'true') {
        globalAlert.style.display = 'block';
    }

    const getAlertHeight = () => {
        if (globalAlert) {
            globalAlertHeight = globalAlert.offsetHeight;
        }
    }

    const offsetListener = () => {
        const globalHeader = document.querySelector('.site-header');
        const main = document.getElementById('app');
        let globalHeaderHeight = globalHeader.offsetHeight;

        // main.style.marginTop = `-${globalHeaderHeight}px`;

        if (globalAlert) {
            if (window.innerWidth > 991) {
                navOverlay.style.paddingTop = `${globalAlertHeight + globalHeaderHeight}px`;
            } else {
                navOverlay.style.paddingTop = `${globalHeaderHeight}px`;
            }
        } else {
            navOverlay.style.paddingTop = `${globalHeaderHeight}px`;
        }
    }
    const alertOffset = () => {
        const siteFooter = document.querySelector('.site-footer');
        if (globalAlert) {
            if (window.innerWidth < 991) {
                siteFooter.style.marginBottom = `${globalAlertHeight}px`;
            } else {
                siteFooter.style.marginBottom = 0;
            }
        }
    }

    if (navOverlay) {
        const navOverlayScrollListener = () => {
            let currPos = navOverlay.scrollTop;
            if (currPos >= 50) {
                document.querySelector('.site-header .navbar').classList.add('navbar-fade');
            } else {
                document.querySelector('.site-header .navbar').classList.remove('navbar-fade');
            }
        }
        navOverlayScrollListener();
        navOverlay.addEventListener('scroll', () => {
            navOverlayScrollListener();
        });
    }

    // Init Functions
    getAlertHeight();
    offsetListener();
    alertOffset();

    window.addEventListener('resize', () => {
        getAlertHeight();
        offsetListener();
        alertOffset();
    });

    // window.lazySizesConfig = window.lazySizesConfig || {};
    //
    // window.lazySizesConfig.expand = 250; //default 360-500
    // lazySizesConfig.expFactor = 1.5; //default: 1.7

    // Refresh form tokens
    if ($("input[name='" + csrfTokenName + "']").length > 0) {
        $(() => {
            $.ajax({
                url: "/dynamicForm",
                success: (response) => {
                    $("input[name='" + csrfTokenName + "']").val(response);
                }
            });
        });
    }

    // toggle attributes and classes for main navigation on click
    document.getElementById('navToggle').addEventListener('click', () => {
        let ariaExpanded = document.getElementById('navToggle').getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            document.getElementById('navToggle').setAttribute('aria-expanded', 'true');
        } else {
            document.getElementById('navToggle').setAttribute('aria-expanded', 'false');
        }
        document.getElementsByTagName('body')[0].classList.toggle('open');
        document.getElementById('navOverlay').classList.toggle('d-none');
    });

    // Listen for escape key press to close main navigation
    document.addEventListener('keydown', function(e) {
        // if keycode equals escape
        if(e.key === "Escape") {
            if (document.getElementsByTagName('body')[0].classList.contains('open')) {
                document.getElementsByTagName('body')[0].classList.remove('open');
                document.getElementById('navToggle').setAttribute('aria-expanded', 'false');
                document.getElementById('navOverlay').classList.add('d-none');
            }
        }
    });

    if (dismissAlert) {
        dismissAlert.addEventListener('click', () => {
            create_cookie('dismissGlobalAlert', 'true', 1, "/");
            globalAlert.style.display = 'none';
        });
    }

    $(window).on('load', function() {
        $('.flexslider').flexslider({
            animation: "fade",
            // controlsContainer: $(".flexslider-container"),
            customDirectionNav: $(".flexslider-navigation a"),
            controlNav: false,
            // directionNav: false,
            slideshow: false,
            touch: true,
        });
    });
});